@import "colors";

#feedbackBtn {
    position: fixed;
    bottom: 20px;
    right: 20px; }

#feedbackText {
    width: 100%;
    height: 150px; }
