@import "colors";
@import "util";
@import "spacing";

.navbar {
    z-index: 9999;

    .navbar-toggler {
        align-self: inherit;
        position: initial; }

    .navbar-brand {
        font-size: 1.8rem;
        font-weight: 700;
        letter-spacing: 3px;
        margin-top: 0;
        text-transform: uppercase; }

    .nav-item {
        margin-right: 20px; }

    .nav-item:hover {
        cursor: pointer; }

    .nav-item:last-of-type {
        margin-right: 0; }

    #userOptions a {} }

.navbar-product {
    padding: $std-space (2 * $std-space);

    .navbar-brand {
        font-size: 1rem; }

    .navbar-brand, .nav-link {
        padding: 0; } }

/** COLORING **/

.navbar-dark {
    background-color: $dk-grey;
    @include shadow();

    .navbar-brand {
        color: white !important; } }

.navbar-dark.dashboard-header {
    background: $white;
    border-bottom: 1px solid $outline;
    margin-bottom: 0;
    box-shadow: none;
    height: 66px;

    .navbar-nav .nav-link {
        color: $text; } }

.navbar-light {
    .navbar-brand {
        color: $text !important; }

    .navbar-nav .nav-link {
        color: $text; } }

@media (max-width: $sm-breakpoint) {
    #navbarCollapse {
        padding: 0 (2 * $std-space);
        background-color: $grey;
        .navbar-nav .nav-link {
            color: white; } } }

.breadcrumb.page-location {
    margin-bottom: 0;
    padding: 0;
    background: $white;

    .breadcrumb-item + .breadcrumb-item {
        display: flex;
        align-items: center;
        padding-left: 10px; }

    .breadcrumb-item + .breadcrumb-item::before {
        content: '';
        width: 16px;
        height: 16px;
        display: block;
        background-image: url(/assets/images/bread-arrow.svg);
        color: $text;
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        margin-right: 10px; }

    .breadcrumb-item a {
        color: $text;
        font-weight: 500;
        font-family: "Mulish", sans-serif; }

    .breadcrumb-item.active {
        color: $grey;
        font-weight: 400; } }

.loggedin .navbar-dark.navbar-product {}

.artifactSearchMainDiv {
    width: 40%;
    height: 50px;
    margin-left: 20%;
    position: absolute; }

#artifactsSearchInput {
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: 2px solid #E2EBFA;
    padding-left: 50px;
    padding-right: 55px;
    background-color: #FFF;
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #000;
    font-weight: 100; }

#artifactsSearchInput:focus-visible {
    border: 2px solid #E2EBFA;
    outline: #E2EBFA; }

.artifactSearchResultsDropdownDiv {
    position: absolute;
    background-color: #FFF;
    width: 49%;
    max-height: 200px;
    min-height: 20px;
    border: 1px solid #F66322;
    border-radius: 8px;
    padding-left: 20px;
    scroll-behavior: smooth;
    overflow-y: scroll; }

.searchResultUnselected {
    color: #5A6070;
    padding: 5px 0px;
    text-decoration: none;
    display: block; }

.searchResultUnselected:hover {
    color: #F66322;
    text-decoration: none; }

.searchResultSelected {
    background-color: #F66322;
    color: #FFFFFF;
    text-decoration: none;
    padding: 5px 0px;
    display: block; }

.searchResultSelected:hover {
    color: #FFFFFF;
    text-decoration: none; }

.upgradeSubscriptionButton {
    width: 80px;
    height: 40px;
    background-color: #f66322;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fafbfb;
    margin-left: 18px; }

.upgradeSubscriptionButton:hover {
    background-color: #ff966f; }

.upgradeSubscriptionButton:focus {
    outline: none; }
