@import "navBar";
@import "feedback";
@import "editableText";
@import "colors";
@import "spacing";
@import "util";
@import "fonts";
@import "leftNav";

.loggedin .container.mainPage {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0; }

.page-wrapper {
    width: 100%;
    overflow-x: hidden;
    display: -ms-flex;
    display: -webkit-flex;
    display: flex;

    .left-nav {
        z-index: 2001;
        width: 246px;
        background-color: $white;
        border-right: 1px solid $outline;
        display: flex;
        flex-direction: column;
        z-index: 9999;

        .logo-area {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 66px;
            border-bottom: 1px solid $outline;

            img {
                max-height: 34px; } } }

    .content-wrapper {
        min-height: 100vh;
        padding: 0;
        position: relative !important;
        flex-shrink: 1;
        width: calc(100% - 246px);

        .content-area {
            padding: 32px;
            height: calc(100vh - 66px);
            #user, #organization {
                max-width: 460px;
                margin: 0 auto; } } } }

.user-top-ddd {
    display: flex;
    align-items: center;

    .initial-c {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $grey;
        border-radius: 53px;
        margin-right: 16px;

        span {
            font-size: 15px;
            line-height: 18px;
            color: $white;
            text-transform: uppercase; } }

    .name-email {
        display: flex;
        flex-direction: column;
        color: $text;

        span {
            font-size: 12px;
            font-weight: 400;
            line-height: 16px; }

        .username {
            font-weight: 500;
            text-transform: capitalize;
            font-size: 16px;
            line-height: 24px; } }

    > img {
        margin-left: 10px; } }

.content-area {
    #user {
        max-width: 460px;
        margin: 0 auto;

        .orgList {
            margin-top: 16px;

            .org-item strong {
                font-weight: 500; } } } }

.org-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 12px 16px;
    background: $outline;
    padding: 12px 16px;
    border-radius: 4px; }

.manageAccountStyle {
    width: 600px;
    max-width: 600px; }

.nameInputRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px; }

.nameUpdateButtonActive {
    width: 100px;
    height: 60px;
    background-color: #f66322;
    border: none;
    color: #fff;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.nameUpdateButtonActive:focus {
    outline: none; }

.nameUpdateButtonInActive {
    width: 100px;
    height: 60px;
    background-color: #f7f7f8;
    border: none;
    color: #656565;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.nameUpdateButtonInActive:focus {
    outline: none; }

.nameInputLabelText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px;
    margin-top: 35px; }

.nameInputStyle {
    width: 100%;
    height: 60px;
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    padding: 15px;
    background-color: transparent;
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #656565;
    font-weight: 100;
    margin-right: 20px; }

.nameInputStyle::placeholder {
    font-family: "Mulish", sans-serif;
    color: #878991;
    font-size: 20px;
    font-weight: 100; }

.nameInputStyle:focus-visible {
    border: 1px solid #E76510;
    outline: #E76510; }

.emailInputRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px; }

.emailInputLabelText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px; }

.emailUpdateButtonActive {
    width: 100px;
    height: 60px;
    background-color: #f66322;
    border: none;
    color: #fff;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.emailUpdateButtonActive:focus {
    outline: none; }

.emailUpdateButtonInActive {
    width: 100px;
    height: 60px;
    background-color: #f7f7f8;
    border: none;
    color: #656565;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.emailUpdateButtonInActive:focus {
    outline: none; }

.emailInputStyle {
    width: 100%;
    height: 60px;
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    padding: 15px;
    background-color: transparent;
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #656565;
    font-weight: 100;
    margin-right: 20px; }

.emailInputStyle::placeholder {
    font-family: "Mulish", sans-serif;
    color: #878991;
    font-size: 20px;
    font-weight: 100; }

.emailInputStyle:focus-visible {
    border: 1px solid #E76510;
    outline: #E76510; }

.myGroupsText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px;
    margin-top: -12px; }

.organizationTitle {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #3A3A3A;
    font-weight: 400;
    margin-bottom: -2px; }

.organizationItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #707070;
    padding: 10px;
    height: 40px;
    width: 60%;
    margin-bottom: 10px; }

.adminControlsText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px; }

.anchorTagsText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #F66322;
    text-decoration: none; }

.anchorTagsText:hover {
    text-decoration: none;
    color: #F66322; }

.inActiveAnchorTagsText {
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #7e7e7e;
    text-decoration: none; }

.inActiveAnchorTagsText:hover {
    text-decoration: none;
    color: #7e7e7e; }

.subscriptionPlanLabelText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px;
    margin-top: -5px; }

.subscriptionPlanDiv {
    width: 83%;
    height: 140px;
    border-radius: 7px;
    border: 1px solid #707070;
    background-color: #FFFFFF;
    margin-bottom: 30px;
    padding: 20px; }

.subscriptionPlanTitle {
    font-size: 20px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    display: flex;
    align-items: center;
    flex-direction: row; }

.proSubscriptionPlanSubsText {
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    color: #7E7E7E;
    font-weight: normal;
    margin-left: 25px;
    margin-bottom: -5px; }

.upgradeToProButton {
    width: 180px;
    height: 55px;
    background-color: #f66322;
    border: none;
    color: #fafbfb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.upgradeToProButton:hover {
    background-color: #ff966f; }

.upgradeToProButton:focus {
    outline: none; }

.enterpriseSubscriptionPlanSubsText {
    font-family: "Mulish", sans-serif;
    font-size: 16px;
    color: #7E7E7E;
    font-weight: normal; }

.premiumDowngradeRowDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }

.proBillingCycleDiv {
    width: 55%; }

.downgradeToFreeButton {
    width: 38%;
    height: 40px;
    background-color: #f7f7f8;
    border: none;
    color: #7e7e7e;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.downgradeToFreeButton:hover {
    background-color: #e9e9e9; }

.downgradeToFreeButton:focus {
    outline: none; }

.confirmationPopper {
    .popper {
        z-index: 999999999;
        width: 600px;
        padding: 22px; } }

.confirmationPopUpMainDiv {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column; }

.confirmationPopUpButtonsDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end; }

.cancelConfirmationButton {
    width: 80px;
    height: 40px;
    background-color: #ffffff;
    border: none;
    color: #f66322;
    border-radius: 5px;
    border: 1px solid #f66322;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin-right: 20px; }

.cancelConfirmationButton:hover {
    background-color: #ffffff; }

.cancelConfirmationButton:focus {
    outline: none; }

.doneConfirmationButton {
    width: 80px;
    height: 40px;
    background-color: #f66322;
    border: none;
    color: #fafbfb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.doneConfirmationButton:hover {
    background-color: #ff966f; }

.doneConfirmationButton:focus {
    outline: none; }

.confirmationText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565; }
