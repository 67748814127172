@import "colors";

.actions-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    flex: 1; }

ul.left-nav-menu {
    margin-top: 40px;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    font-size: 16px;
    font-family: "Mulish", sans-serif;

    li {
        padding-left: 0;
        margin-bottom: 16px;
        padding-right: 20px;
        position: relative;

        a {
            width: 100%;
            display: block;
            display: flex;
            padding: 10px 0 10px 20px;
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
            color: $sideNav-dk-grey;
            align-items: center;
            text-decoration: none;
            transition: all 0.2s;

            svg {
                margin-right: 10px; } }

        a:hover {
            background-color: $sideNav-lt-orange; } }

    li.active {
        a {
            background-color: $sideNav-dk-orange;
            color: $white; }

        a:hover {
            text-decoration: none; } }

    li:last-child {
        margin-bottom: 0; } }

.separator {
    height: 1px;
    background-color: $bdr-grey;
    margin-top: 15px;
    margin-bottom: 15px;
    opacity: 1;
    margin-right: 20px;
    margin-left: 20px; }

.sideNavHeadingText {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 20px;
    margin-left: 20px;
    color: #B0B0B9;
    font-size: 10px; }

.dashboardDraftlensLogo {
    width: 228px; }

.dynamicTabsText {
    width: 85%; }

.myTemplates {
    margin-right: 10px; }

.feedback-portion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;

    >img {
        max-width: 54px;
        margin-bottom: 24px; }

    button#feedbackBtn.feedback-btn {
        position: relative;
        bottom: 0;
        left: 0;
        background: $white;
        color: $orange;
        box-shadow: none;
        border: 1px solid $orange;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 6px 16px; }

    button#feedbackBtn.feedback-btn:hover {
        background: $orange;
        color: $white; } }
