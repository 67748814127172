.editableText {
    cursor: pointer;
    display: inline-block;
    min-width: 1rem;

    div:hover {
        background-color: $orange; }

    input {
        background: transparent;
        border: none;
        display: inline-block;
        width: 5rem; }

    input:focus { // chrome
        outline-offset: 0px;
        outline: none; } }

