@import "structure";
@import "navBar";
@import "feedback";
@import "editableText";
@import "colors";
@import "spacing";
@import "util";
@import "fonts";

html, body {
    color: $text;
    background-color: $body-bg;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0; }

.mainPage {
    background-color: $body-bg; }

.loginResetPassswordMainPage {
    background-color: $body-bg;
    position: fixed;
    align-items: center;
    justify-content: center;
    display: flex; }

.rectangleBackgroundImage {
    width: 90vw;
    max-width: 50%;
    margin-left: -55%;
    height: 50%;
    transition: width .5s, height .5s;
    @media screen and ( max-width: 1600px ) {
        width: 80vw;
        max-width: 70%; }
    @media screen and ( max-width: 1500px ) {
        width: 75vw;
        max-width: 75%; }
    @media screen and ( max-width: 1500px ) {
        width: 70vw;
        max-width: 65%;
        height: 100vh; }
    @media screen and ( max-width: 1400px ) {
        width: 70vw;
        max-width: 50%;
        margin-left: -55%; }
    @media screen and ( max-width: 1300px ) {
        width: 65vw;
        max-width: 50%;
        margin-left: -60%; }
    @media screen and ( max-width: 1200px ) {
        width: 60vw;
        max-width: 40%;
        margin-left: -65%;
        height: 100vh; }
    @media screen and ( max-width: 1100px ) {
        width: 40vw;
        max-width: 40%; }
    @media screen and ( max-width: 1000px ) {
        display: none; }
    @media screen and ( max-height: 1800px ) {
        height: 100vh; } }

.curvedBackgroundImage {
    width: 90vw;
    max-width: 70%;
    margin-left: -35%;
    height: 50%;
    transition: width .5s, height .5s;
    @media screen and ( max-width: 1600px ) {
        width: 80vw;
        max-width: 80%; }
    @media screen and ( max-width: 1500px ) {
        width: 75vw;
        max-width: 75%;
        height: 100vh; }
    @media screen and ( max-width: 1400px ) {
        width: 70vw;
        max-width: 70%;
        margin-left: -33%; }
    @media screen and ( max-width: 1300px ) {
        width: 65vw;
        max-width: 65%;
        margin-left: -38%; }
    @media screen and ( max-width: 1200px ) {
        width: 70vw;
        max-width: 70%;
        height: 100vh; }
    @media screen and ( max-width: 1100px ) {
        width: 75vw;
        max-width: 75%; }
    @media screen and ( max-width: 1000px ) {
        display: none; }
    @media screen and ( max-height: 1800px ) {
        height: 100vh; } }


.widePage {
    @media (min-width: 1540px) {
        .container {
            width: 100%;
            max-width: 100%; } } }

button {
    cursor: pointer; }

.btn {
    font-family: $title-font; }

.modal-header {
    background: $outline;

    button {
        box-shadow: initial; } }

a {
    color: $blue;
    cursor: pointer; }

a:hover {
    color: $dk-blue; }

.hide {
    display: none !important; }

.odd {
    background-color: #f8f8f8; }

.progress-bar {
    width: 75%; }

.loadingContainer {
    background-color: $white;
    top: 0;
    left: 0;
    z-index: 99999;
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .lds-ring {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px; }

    .loader-text {
        font-size: 18px;
        color: $grey;
        margin-bottom: 0;
        margin-top: 16px; }
    .downloading-btn-txt {
        font-size: 18px;
        color: $orange;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: 16px;
        margin-bottom: 60px; }

    .success-download-btn {
        position: absolute;
        width: 300px;
        bottom: 24px; }
    .direction-arrow {
        position: absolute;
        top: calc(50% + 80px);
        right: calc(50% - 260px); }
    .failed-text {
        color: $orange; } }

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $orange;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $orange transparent transparent transparent;

    &:nth-child(1) {
        animation-delay: -0.45s; }

    &:nth-child(2) {
        animation-delay: -0.3s; }

    &:nth-child(3) {
        animation-delay: -0.15s; } }

@keyframes lds-ring {
    0% {
        transform: rotate(0deg); }

    100% {
        transform: rotate(360deg); } }
.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;

    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px; }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in; }

        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg); }

        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s; }

            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s; } }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5); }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF; } } }

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg); }

    5% {
        transform: rotate(-45deg); }

    12% {
        transform: rotate(-405deg); }

    100% {
        transform: rotate(-405deg); } }

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px; }

    54% {
        width: 0;
        left: 1px;
        top: 19px; }

    70% {
        width: 50px;
        left: -8px;
        top: 37px; }

    84% {
        width: 17px;
        left: 21px;
        top: 48px; }

    100% {
        width: 25px;
        left: 14px;
        top: 45px; } }

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px; }

    65% {
        width: 0;
        right: 46px;
        top: 54px; }

    84% {
        width: 55px;
        right: 0px;
        top: 35px; }

    100% {
        width: 47px;
        right: 8px;
        top: 38px; } }

.tooltip-inner {
    background: $white;
    color: $text;
    font-size: 12px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); }

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    display: none; }

.clickable {
    cursor: pointer !important; }

.cursor-wait {
    cursor: progress !important; }

.custom-control-label:hover {
    color: $orange;
    cursor: pointer; }

*[fakeHref] {
    cursor: pointer; }

.dropdown-menu {
    margin: 0; }

.fadeIn {
    opacity: 0 !important;
    transition: opacity .5s ease-out;
    -moz-transition: opacity .5s ease-out;
    -webkit-transition: opacity .5s ease-out;
    -o-transition: opacity .5s ease-out; }

.fadeIn:hover {
    opacity: 1 !important; }

.warningIcon {
    color: $warning;
    opacity: .8;
    padding-right: $icon-padding; }

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.orLine {
    overflow: hidden;
    text-align: center; }

.orLine:before, .orLine:after {
    background-color: $grey;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%; }

.orLine:before {
    right: 0.5em;
    margin-left: -50%; }

.orLine:after {
    left: 0.5em;
    margin-right: -50%; }

.infoBlurb {
    font-size: 14px;
    color: $lt-grey;
    margin-bottom: 8px; }

.infoIcon {
    margin-left: $icon-padding;
    color: rgba(0,0,0,.5); }

.alertContainer {
    width: 50%;
    margin: (2 * $std-space) 25%;
    position: absolute;
    z-index: 99999999;
    @include shadow();
    @include rounded(); }

.alert {
    margin-bottom: 0;
    bottom: 50px;
    right: 50px;
    position: fixed;
    z-index: 9999999; }

.textInput {

    input, textarea {
        color: $text;
        width: 100%;
        border: 1px solid $outline;
        background: #FBFBFD;
        padding: 8px;
        padding-right: $std-space;
        @include rounded(); }

    input:focus, textarea:focus {
        color: black;
        border-color: $lt-blue;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25); }

    .variationsTextArea {
        width: 100%;
        height: 100;
        border: 1px solid #707070;
        border-radius: 10px;
        resize: none;
        box-shadow: none; }

    .variationsTextArea:focus-visible {
        border: 1px solid #F66322;
        outline: #F66322; } }

.textAreaInput {
    label {
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 24px; } }

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px; } }

.modal-body {
    max-height: 500px;
    overflow: auto; }

.fa, .fab, .fal, .far, .fas {
    line-height: 1.5; }

.combo-wrapper {
    overflow: hidden;
    cursor: pointer;

    .table-heading {
        display: flex;
        align-items: center;
        padding: 16px;
        justify-content: space-between;
        width: 100%;
        border: 1px solid $outline;
        border-bottom: 0;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;

        h2 {
            font-size: 20px;
            line-height: 25px;
            color: $sideNav-dk-grey;
            margin-bottom: 0;
            font-family: "Mulish", sans-serif;
            font-weight: 600; } }

    .draft-table {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px; } }

.orgsUserSuggestionDiv {
    display: flex;
    flex-direction: column;
    align-items: center; }

.orgsUserSuggestionInput {
    width: 100%;
    height: 42px;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 12px;
    background-color: #F5F8FF;
    color: #878991;
    margin-bottom: 12px;
    margin-top: 5px; }

.orgsUserSuggestionInput::placeholder {
    font-family: "Mulish", sans-serif;
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 18px;
    line-height: 23px;
    font-weight: 100; }

.orgsUserSuggestionInput:focus-visible {
    border: 1px solid #F66322;
    outline: #F66322; }

.orgUserSuggestionDropdown {
    width: 92.5%;
    border-radius: 10px;
    margin-top: 47px;
    max-height: 200px;
    position: absolute;
    overflow-y: auto;
    padding: 12px;
    border: 1px solid #E2EBFA;
    background-color: #F5F8FF; }

.addUserButton {
    align-self: flex-start; }

.suggestedUserDetail {
    margin-left: 16px; }

.suggestedUserEmail {
    letter-spacing: 0.18px;
    color: #878991;
    font-size: 16px;
    font-family: "Mulish", sans-serif;
    line-height: 23px;
    font-weight : lighter; }

.suggestedUserName {
    font-family: "Mulish", sans-serif;
    color: $sharePopper-dk-grey;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 5px;
    letter-spacing: 0.18px; }

.suggestedUserDp {
    display: flex;
    align-items: center;
    margin-top: 15px;
    cursor: pointer; }

.removeOrgsIcon {
    cursor: pointer; }

.createOrganizationButton {
    width: 180px;
    height: 45px;
    background-color: #f66322;
    border: none;
    outline: none;
    border-radius: 5px;
    color: #fafbfb;
    margin-left: 69%;
    font-weight: 500; }

.createOrganizationButton:hover {
    background-color: #ff966f; }

.createOrganizationButton:focus {
    outline: none; }

.orgname:hover {
    color: #F66322;
    text-decoration: none; }

.orgsDotButton {
    width: 35px;
    height: 35px; }

.nameInputDiv {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 30%; }

.orgNameInputStyle {
    width: 100%;
    height: 60px;
    border: 1px solid #A0A0A0;
    border-radius: 5px;
    padding: 15px;
    background-color: transparent;
    font-family: "Mulish", sans-serif;
    font-size: 20px;
    color: #656565;
    font-weight: 100;
    margin-right: 20px; }

.orgNameInputStyle::placeholder {
    font-family: "Mulish", sans-serif;
    color: #878991;
    font-size: 20px;
    font-weight: 100; }

.orgNameInputStyle:focus-visible {
    border: 1px solid #E76510;
    outline: #E76510; }

.orgNameInputLabelText {
    font-size: 18px;
    font-family: "Mulish", sans-serif;
    font-weight: bold;
    color: #656565;
    margin-bottom: 5px;
    margin-top: 30px; }

.organizationNameText {
    font-family: "Mulish", sans-serif;
    color: #656565;
    font-size: 30px; }

.orgBorder {
    border: 1px solid #E3EBFB; }

.nameActionsDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between; }

.emailDomainCheckbox {
    accent-color: #e16225;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid #909090;
    border-radius: 3px;
    margin-top: -18px; }

.emailDomainCheckbox:hover {
    cursor: pointer;
    border-color: 2px solid #e16225; }

.emailDomainCheckboxRow {
    display: flex;
    align-items: center;
    flex-direction: row; }

.emailDomainCheckboxText {
    font-family: "Mulish", sans-serif;
    color: #656565;
    font-size: 18px;
    font-weight: 500; }

.orgsConfirmationPopUpButtonsDiv {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end; }

.orgsConfirmationPopUpMainDiv {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column; }

.orgsCancelBtn {
    width: 80px;
    height: 40px;
    background-color: #ffffff;
    border: none;
    color: #f66322;
    border-radius: 5px;
    border: 1px solid #f66322;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    margin-right: 15px; }

.orgsCancelBtn:hover {
    background-color: #ffffff; }

.orgsCancelBtn:focus {
    outline: none; }

.orgsDoneBtn {
    width: 150px;
    height: 40px;
    background-color: #f66322;
    border: none;
    color: #fafbfb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none; }

.orgsDoneBtn:hover {
    background-color: #ff966f; }

.orgsDoneBtn:focus {
    outline: none; }

.addUserPopperButtonsRow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
    position: absolute;
    bottom: 17px;
    right: 22px; }

.orgsAddUserPopper {
    .popper {
        z-index: 999999999;
        width: 600px;
        height: 500px;
        padding: 22px; } }

.addedUserDp {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    max-height: 350px;
    overflow-y: auto; }

.removeDummyUserRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px; }

.mainNavBartooltip-text {
    visibility: visible;
    position: absolute;
    box-shadow: 0px 0px 4px #222;
    z-index: 1;
    width: 120px;
    height: 20px;
    bottom: -1.3em;
    left: -10px;
    color: #E5E5E5;
    font-size: 12px;
    background-color: #5F5F5F;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    display: flex; }

.mainNavBartooltip-text::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #5F5F5F;
    bottom: 15px;
    padding: 5px;
    z-index: 1; }
